<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              機關列表
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                :label="$t(`common['Search']`)"
                single-line
                hide-details
              ></v-text-field>
              <span class="pt-5" v-if="canEdit">
                <v-btn
                  class="
                    font-weight-normal
                    text-capitalize
                    btn-primary
                    bg-gradient-secondary
                    py-3
                    px-6
                    ms-3
                  "
                  @click="onEditItem(null)"
                >
                  {{ $t(`common["Add"]`) }}
                </v-btn>
              </span>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="tableHeaders"
                :items="tableData"
                :loading="!isLoaded"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :search="search"
                :sort-by="['id']"
                :sort-desc="[false]"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
                <!-- <template v-slot:item.createdAt="{ item }">
                  <span>{{
                    item.createdAt
                      ? moment.unix(item.createdAt).format("MM/DD/YYYY")
                      : ""
                  }}</span>
                </template> -->

                <template v-slot:item.actions="props">
                  <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        color="blue darken-2"
                        v-on="on"
                        @click="gotoPage(props.item)"
                      >
                        <v-icon>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>查看單位列表</span>
                  </v-tooltip> -->
                  <v-tooltip bottom v-if="canEdit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        color="cyan"
                        v-on="on"
                        @click="onEditItem(props.item)"
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>編輯</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <Pagination
                :pagination="pagination"
                :total="tableData.length"
                :loading="!isLoaded"
                :show-items-per-page="false"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="openEditDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ isNew ? $t(`common["Add"]`) : $t(`common["Edit"]`) }}
            </span>
            <span class="text-h5">
              {{ $t(`sidebar["Organization Management"]`) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editItem.organizationName"
                      label="機關名稱"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSaveEditItem">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { fetchOrganizations } from "src/apis/fetchData";
import { updateOrganization } from "src/apis/updateData";
import { checkPermission } from "src/util/utils";
import Pagination from "../Campaigns/Widgets/Pagination.vue";

export default {
  name: "Organizations",
  components: { Pagination },
  data() {
    return {
      moment: moment,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      isLoaded: true,
      isNew: false,
      openEditDialog: false,
      isOrganizationLoaded: true,
      tableData: [],
      tableHeaders: [
        {
          text: "ID",
          sortable: true,
          value: "organizationId",
        },
        {
          text: "機關名稱",
          sortable: true,
          value: "organizationName",
        },

        // {
        //   text: "建立時間",
        //   sortable: true,
        //   filterable: false,
        //   value: "createdAt",
        // },
        { text: "", value: "actions", sortable: false },
      ],
      search: null,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      defaultEditItem: {
        organizationName: null,
      },
      editItem: {
        organizationName: null,
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
    }),
    isActionDisabled() {
      return !this.isOrganizationLoaded;
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:AccountManage",
          "EditOrganizations",
          true
        );
      }
      return false;
    },
  },
  watch: {},
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.init();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.init();
    }
  },
  methods: {
    gotoPage(item) {
      this.$router.push({
        name: "Account Management",
        query: {
          categoryId: item.id,
        },
      });
    },
    init() {
      this.getOrganizations();
    },
    getOrganizations() {
      this.isOrganizationLoaded = false;
      fetchOrganizations(this.merchantId, null)
        .then((res) => {
          console.log("fetchOrganizations done", res);
          this.tableData = [...res.data];
        })
        .catch((e) => {
          console.log("fetchOrganizations failed", e);
        })
        .finally(() => {
          this.isOrganizationLoaded = true;
        });
    },

    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      this.openEditDialog = false;
    },
    onEditItem(item) {
      if (item) {
        this.editItem = Object.assign({}, item);
        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        this.isNew = true;
      }

      this.openEditDialog = true;
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      if (valid) {
        // console.log("------", this.editItem);
        // if (!this.editItem.organizationId)
        //   this.editItem.organizationId = this.organizationId;

        updateOrganization(this.merchantId, this.editItem)
          .then((res) => {
            console.log("updateOrganization done", res);
            this.getOrganizations();
            this.openEditDialog = false;
            this.$refs.editForm.resetValidation();
            this.$refs.editForm.reset();
          })
          .catch((e) => {
            console.log("updateOrganization failed", e);
            let errmsg = this.isNew ? "新增機關失敗" : "更新機關失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
/* .radio-large::v-deep .v-icon {
  font-size: "24px !important";
} */
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
/* .large-radio::v-deep [class*="__ripple"] {
  left: 0;
} */
</style>
